import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core'; 
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {HttpClientModule} from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatStepperIntl } from '@angular/material/stepper';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RelatorioService } from './services/relatorio.service';
import { AuthService } from './services/auth.service';
import { ErrorcodeService } from './services/errorcode.service';
import { FirestoreSettingsToken } from '@angular/fire/firestore';
import { AppComponent } from './app.component';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { ToastrModule } from 'ngx-toastr';
import { FirestoreService } from './services/firestore.service';
import { DialogBoxLuComponent } from './components/dialog-box-lu/dialog-box-lu.component';
import { getPaginatorIntl } from './paginator-intl';
import { DialogBoxWarningComponent } from './components/dialog-box-warning/dialog-box-warning.component';
import { getMatStepperIntl } from './mat-stepper-intl';
import { RedefinirSenhaComponent } from './components/redefinir-senha/redefinir-senha.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogBoxValoresComponent } from './components/dialog-box-valores/dialog-box-valores.component';

export const firebaseConfig = {
  apiKey: "AIzaSyANyLgkAaDBhe7IHbyYbwUIlOX_3ZZ-xuA",
  authDomain: "relatoriosoftenge.firebaseapp.com",
  projectId: "relatoriosoftenge",
  storageBucket: "relatoriosoftenge.appspot.com",
  messagingSenderId: "49022946856",
  appId: "1:49022946856:web:4c360168e8c652a7df2f1d"
};

@NgModule({
  declarations: [
    AppComponent,
    DialogBoxLuComponent,
    DialogBoxWarningComponent,
    RedefinirSenhaComponent,
    DialogBoxValoresComponent,
  ],
  imports: [ 
    ReactiveFormsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [ RelatorioService, AuthService, ErrorcodeService, FirestoreService,
  { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  { provide: MatStepperIntl, useValue: getMatStepperIntl() },
  { provide: FunctionsRegionToken, useValue: 'southamerica-east1' }, 
  { provide: FirestoreSettingsToken, useValue: {}}],
  bootstrap: [AppComponent],
  entryComponents: [DialogBoxLuComponent, DialogBoxWarningComponent, DialogBoxValoresComponent]
})
export class AppModule { }