import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorcodeService } from 'src/app/services/errorcode.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent implements OnInit {

  public error:any;
  public emailSent = false;
  Email:string;
  @ViewChild('email') private email:ElementRef;

  constructor(private auth:AuthService, private errorCodeService:ErrorcodeService) { }

  ngOnInit() {
  }

  // Envia email para o endereço digitado com protocolo de redefinição de senha
  public enviarEmail():void {
    this.Email = this.email.nativeElement.value;
    let actionCodeSettings:firebase.auth.ActionCodeSettings = {handleCodeInApp:true, url:'https://relatorio-demo-softenge.firebaseapp.com'};
      this.auth.afAuth.auth.sendPasswordResetEmail(this.Email, actionCodeSettings).then(() => {
        this.error = null;
        this.emailSent = true;
      }).catch(err => {
        this.error = this.errorCodeService.errorCodeTest(err.code);
      });
  }

}
