import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ListusersService {

  public usersAdmin = [];
  public usersDev = [];

  private subject = new Subject(); // Declaração de um subject, necessário para criação e comunicação do observable
  public cast = this.subject.asObservable(); // Criação do observable cast 
  
  constructor(private functions: AngularFireFunctions, private authService: AuthService) {

   }

   // Next chama todos os componentes subscritos no Observable passando os argumentos para eles
   public listUsers():void {

    // listUsers recebe https de função presente na nuvem
    const listUsers = this.functions.httpsCallable('listAllUsers');

    // chama função que retorna lista de usuários
    listUsers(100).toPromise().then(list => {
      // para cada usuário presente na lista extrai-se os dados importantes e os coloca em userList
      if (!this.authService.dev) {
        this.usersAdmin = []
        list.forEach(user => {
          if (!user.customClaims.dev) {
            this.usersAdmin.push({nome:user.displayName, email:user.email, claim:user.customClaims.admin, dev:user.customClaims.dev,
            criacao:user.metadata.creationTime, lastSignIn:user.metadata.lastSignInTime, uid:user.uid})
          }
        });
        this.subject.next();
        
      } else {
        this.usersDev = []
        list.forEach(user => {
          this.usersDev.push({nome:user.displayName, email:user.email, claim:user.customClaims.admin, dev:user.customClaims.dev,
          criacao:user.metadata.creationTime, lastSignIn:user.metadata.lastSignInTime, uid:user.uid})
        });
        this.subject.next();
      }

    })
  }  

  unsubscribe() {
    this.subject.next();
    this.subject.complete();
  }

}
