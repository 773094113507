import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { ListusersService } from './services/listusers.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private authService:AuthService, private listUsers:ListusersService){
    this.listUsers.listUsers();
  }
}
