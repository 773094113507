import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { ToastrService } from 'ngx-toastr';
import { ErrorcodeService } from './errorcode.service';
import { FirestoreService } from './firestore.service';
import { auth } from 'firebase';
import { Router } from '@angular/router';

@Injectable({
providedIn: 'root'
})
export class AuthService {

    public user: any;
    public loggedInStatus = false;
    public admin = false;
    public dev = false;
    public numdocs:number;
    public numdocsViagens:number;

    constructor(public afAuth: AngularFireAuth, public router: Router, 
                public ngZone: NgZone, private toastr: ToastrService, private errorCodeService: ErrorcodeService,
                private firestoreService: FirestoreService) {
                    this.afAuth.auth.onAuthStateChanged(user => {
                    this.user = user
                    this.isLoggedIn(user);
                  })
                };

    public isLoggedIn(user):void {
        if (user) {
            this.checkNDocsRelatorios();
            this.loggedInStatus = true;
            this.toastr.success(`${this.user.email} logado`, '', {timeOut: 5000});
            this.user.getIdTokenResult().then(idTokenResult => {
                this.admin = idTokenResult.claims.admin;
                this.dev = idTokenResult.claims.dev;
                 
                this.ngZone.run(() => {
                    this.router.navigateByUrl('home/pesquisa-relatorio');
                })
            }).catch(err => {
                this.toastr.error(this.errorCodeService.errorCodeTest(err.code), '', {timeOut: 5000});
            })
        } else {
            this.ngZone.run(() => {
              this.router.navigateByUrl('login');
            })
            this.loggedInStatus = false;
            this.admin = false;
        }
    }

    // Realiza download do valor de numdocs(número de relatorios) do ano atual para que fique 
    // armazendado em cache e cria doc caso não exista
    public checkNDocsRelatorios() {
    let num = "numdocs" + (new Date).getFullYear().toString().substring(2);
    this.firestoreService.af.firestore.collection("Relatorios").doc(this.user.uid).get()
          .then(doc => {
            this.firestoreService.isPersistent = doc.metadata.fromCache;
            if(doc.exists) {
            this.numdocs = doc.data()[num];
            if (!this.numdocs) {
              // Um novo contador de documentos(relatórios) é criado para aquele ano com valor 0
              this.firestoreService.af.firestore.collection('Relatorios').doc(this.user.uid).update({
                [num]: 0
              });
            }
          } else { 
            this.firestoreService.af.firestore.collection('Relatorios').doc(this.user.uid).set({
              [num]: 0
            });
          }
          })
    }

    // envia email para endereço do usuário recém criado
    public sendVerificationEmail(email:string) {
        let actionCodeSettings:auth.ActionCodeSettings = {handleCodeInApp:true, url:'https://fir-relatorio.firebaseapp.com'};
        this.afAuth.auth.sendSignInLinkToEmail(email, actionCodeSettings);
    }

    public logout() {
      this.afAuth.auth.signOut().catch(err => {
          if (err) {
            this.toastr.success('Erro durante o Logout.', '', {timeOut: 10000});
          };}).then(() => {
            
            this.toastr.success('Logout executado com sucesso.', '', {timeOut: 5000});
          });
    }
}
