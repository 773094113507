import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorcodeService {

  constructor() { }

  // traduz códigos de erro para mensagens em português
  public errorCodeTest(errorCode):string {
    switch (errorCode) {
      case 'auth/invalid-password': 
        return 'Senha inválida';
      case 'auth/email-already-exists': 
        return 'Já há um usuário com este e-mail';
      case 'auth/email-already-in-use': 
        return 'Já há um usuário com este e-mail';
      case 'auth/invalid-email': 
        return 'Endereço de e-mail inválido';
      case 'auth/operation-not-allowed': 
        return 'Operação não permitida'
      case 'auth/weak-password': 
        return 'Senha fraca, redefina sua senha'
      case 'auth/user-disabled': 
        return 'Usuário foi desabilitado'
      case 'auth/user-not-found': 
        return 'Usuário com este e-mail não encontrado'
      case 'auth/wrong-password': 
        return 'Senha incorreta' 
      case 'auth/network-request-failed':
        return 'Falha na conexão com a internet'
      default: 
        return 'Erro Sem Idendificação ' + 'Código do erro: ' + errorCode; 
    }
  }
}
