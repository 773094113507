import { Injectable } from '@angular/core';
import { QuantidadeHoras } from '../interfaces/quantidade-horas';
import { FirestoreService } from './firestore.service';
import { AuthService } from './auth.service';
import { Horas } from '../interfaces/horas';
import { ToastrService } from 'ngx-toastr';
import { ControleDeHoras } from '../interfaces/controle-de-horas';
import { Dados } from '../interfaces/dados';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  // Totalizações
  private quantidadeHoras: QuantidadeHoras; // Objeto quantidade de horas possui os resultados totais do componente controle de horas
  public horaNormalIni: number; // armazena horário inicial normal de trabalho
  public horaNormalFim: number; // armazena horário final normal de trabalho
  private horas: Horas;

  // Diferencia edição de um relatorio antigo à criação de um novo
  public editMode = false;
  public criacao = false;

  // Dados para criação da coletânea e documento corretos no Firebase
  private numdocs:number;
  public ref:string[] = [];
  public relatorioAtual:string;
  public autorRelAtual:string;

   /* Inicialização dos valores das interfaces buscando no localstorage e atribuindo valor padrão se não houver dados salvos*/
  constructor(private firestoreService: FirestoreService, private authService:AuthService,
              private toastr: ToastrService) { 
    this.quantidadeHoras = { horaNormal: 0, horaExtra50: 0, horaExtra100: 0, horaDeslocamento: 0, 
                             horaDeslocamento50: 0, horaDeslocamento100: 0, kmRodado: 0, diariaTecnica: 0 }; 

    this.horas = { horaNormal: [], horaExtra50: [], horaExtra100: [],
                   deslNormal: [], deslExtra50: [], deslExtra100: [], diaria: [] }
                   
    this.firestoreService.af.firestore.collection("Configuracoes").doc('Ajustes').onSnapshot(snapshot =>{
      this.horaNormalIni = this.hourToNumber(snapshot.data().HorarioNormal.horaInicial);
      this.horaNormalFim = this.hourToNumber(snapshot.data().HorarioNormal.horaFinal);
    })
    
  } 

  // Atualiza dados do Relatório no Firebase
  public setDatabase(dados:Dados):void {
    let name:string;
    let date = new Date;
    let dateYear = date.getFullYear().toString().substring(2);
    let num = "numdocs" + dateYear;
    let numRelatorio:string;
    this.numdocs = this.authService.numdocs;
    let collection = "RSEs";

    if (this.authService.dev) collection = "Dev-RSEs"

    // Quando em modo de edição
    if (this.editMode) {
      
      // Puxa os dados pela referência: número do relatório, data antiga (antes da edição) e o autor
      this.firestoreService.af.firestore.doc(this.ref[0]).get().then((docs) => {
        this.firestoreService.isPersistent = docs.metadata.fromCache;

        dados.relatorio.Relatorio_Servicos.Numero_do_Relatorio = docs.data().Relatorio.Relatorio_Servicos.Numero_do_Relatorio;
        
        // Caso o ano da data antiga seja o mesmo que o atual, o número do relatório permanecerá o mesmo
        // e os demais dados serão atualizados
    
        this.firestoreService.af.firestore.doc(this.ref[0]).update({
          Totalizacao: {Despesas: dados.despesas, Quantidade_de_Horas: dados.qtdHoras},
          Relatorio: dados.relatorio,
          Quantidade_de_Horas_por_Dia: dados.horas,
          Data_Inicial_Trabalho:dados.dataIniTrab,
          Data_Final_Trabalho:dados.dataFimTrab
        }).then(() => { // sucesso
          this.toastr.success("Relatório salvo com sucesso", '', {timeOut: 5000});

        }).catch(err => { // erro
          if(err) this.toastr.error("Erro ao salvar relatório!!!", '', {timeOut: 10000});
        });
      }).finally( () => {
        if (this.firestoreService.isPersistent) {
          this.toastr.success("Relatório salvo localmente", '', {timeOut: 5000});
        }
      })      

    } 

    // Quando em Novo Relatório
    else {
      // Procura número do último relatório escrito
      // Se houver (Há pelo menos um relatório escrito pelo usuário), 
      // this.numRelatorio recebe número do relatório a ser criado
      
      this.firestoreService.af.firestore.collection("Relatorios").doc(this.authService.user.uid).get()
      .then(doc => {
        this.firestoreService.isPersistent = doc.metadata.fromCache;

        this.numdocs= doc.data()[num] + 1;
        numRelatorio = (this.numdocs).toString();
        
        // Atualiza this.numRelatorio com modelo de três dígitos 
        if (numRelatorio.length == 1) numRelatorio = "00".concat(numRelatorio); 
        if (numRelatorio.length == 2) numRelatorio = "0".concat(numRelatorio);
      }).finally( () => {

        // Um novo doc é criado
        // Isso evita que todos os documentos na pasta RSEs sejam deletados pelo set
          this.firestoreService.af.firestore.collection('Relatorios').doc(this.authService.user.uid).update({
            [num]: parseInt(numRelatorio)
          });
    
        // name receve composição do nome da pasta do novo relatório
        name = "RSE" + dateYear + numRelatorio;
        // o nome também é armazenado numa variável dentro da pasta
        dados.relatorio.Relatorio_Servicos.Numero_do_Relatorio = name;

        if (this.firestoreService.isPersistent) {
          this.toastr.success("Relatório salvo localmente", '', {timeOut: 5000});
          this.setRef("Relatorios/"+this.authService.user.uid+"/" + collection + "/"+ name, this.authService.user.displayName);
          this.setEditMode(true); 
        }

        // Pasta do novo relatório é criada já com os dados digitados pelo usuário e
        // os dados gerados por este serviço a partir deles
        this.firestoreService.af.firestore.collection('Relatorios').doc(this.authService.user.uid)
        .collection(collection).doc(name).set({
          Totalizacao: {Despesas: dados.despesas, Quantidade_de_Horas: dados.qtdHoras},
          Relatorio: dados.relatorio,
          faturado: 'Não',
          encerrado: 'Não',
          Quantidade_de_Horas_por_Dia: dados.horas,
          Autor: this.authService.user.displayName,
          Data_Inicial_Trabalho:dados.dataIniTrab,
          Data_Final_Trabalho:dados.dataFimTrab
        }).then(() => { // sucesso
          this.setRef("Relatorios/"+this.authService.user.uid+"/" + collection + "/"+ name, this.authService.user.displayName);
          this.setEditMode(true); 
          this.toastr.success("Relatório salvo com sucesso", '', {timeOut: 5000});

        }).catch(err => { // erro
          if(err) this.toastr.error("Erro ao salvar Relatório!!!", '', {timeOut: 10000});
        });
      });
    }
  }

  public calculaResultados(Controle_de_Horas) {
    this.pushObject(Controle_de_Horas);
    this.calcControleHoras(Controle_de_Horas);
    return this.quantidadeHoras;
  }

  // Atribui valor 0 às propriedades dos objetos presentes em array
  private pushObject(array:ControleDeHoras[]):void {
    let length = array.length;
    let arrayKey = Object.keys(this.horas);

    for (let j = 0; j < arrayKey.length; j++) {
      for (let i = 0; i < length; i++) {
        this.horas[arrayKey[j]][i] = 0;
      }
    }
  }

  // Chama funções que realizam cálculos gerais do relatório como horas normais, horas extras,
  // e somatório de despesas
  private calcControleHoras(contHour:ControleDeHoras[]):void {

    // recebe a quantidade de dias presentes em controle de horas
    let length = contHour.length; 

    // soma todos os km rodados
    this.quantidadeHoras.kmRodado = this.calcKmRodado(contHour, length);

    // soma todas as diárias técnicas
    this.quantidadeHoras.diariaTecnica = this.calcDiariaTecnica(contHour, length);

    // realiza o cálculo de horas normais e horas extras
    [this.quantidadeHoras.horaNormal, this.quantidadeHoras.horaExtra50, this.quantidadeHoras.horaExtra100]
    = this.calcHorasTrabalhadas(contHour, length);

    // realiza o cálculo de deslocamento normal e deslocamentos extras
    [this.quantidadeHoras.horaDeslocamento, this.quantidadeHoras.horaDeslocamento50, this.quantidadeHoras.horaDeslocamento100]
    = this.calcHorasDeslocamento(contHour, length);
  }

  // soma todos os km rodados presentes no relatório
  private calcKmRodado(contHour:ControleDeHoras[], length:number):number {
    let kmRodado:number;
    let element:string; 
    let soma = 0;

    for (let i = 0; i < length; i++) {
  
      if (contHour[i].Km_Rodado) {
        // retira caracteres da string e a transforma em inteiro
        element = contHour[i].Km_Rodado;
        element = element.replace('.', '');
        element = element.replace('km', '');
        kmRodado = parseInt(element); 
      } else kmRodado = 0; // se não houver valor na string, atribui 0 a km rodado
      soma += kmRodado; // soma recebe soma dos valores de km rodado
    }
    return  soma; 
  }  

  // soma todas as diárias técnicas do relatório
  private calcDiariaTecnica(contHour:ControleDeHoras[], length:number):number {
    let diariaTecnica:number;
    let element:string; 
    let soma = 0;

    for (let i = 0; i < length; i++) {
      // transforma valor de string para número
      element = contHour[i].Diaria;
      if (element == 'Nenhuma') diariaTecnica = 0; 
      else if (element== 'Meia') diariaTecnica = 0.5;
      else diariaTecnica = 1;
      
      // soma recebe soma de todas as diárias
      soma += diariaTecnica;
    }
    return soma;
  }

  // realiza o cálculo de horas normais e horas extras do relatório
  private calcHorasTrabalhadas(contHour:ControleDeHoras[], length:number):number[] {
    let soma1 = 0;
    let soma2 = 0;
    let soma3 = 0;
    
    for (let i = 0; i < length; i++) {

      let horaNormal:number;
      let horaExtra50:number;
      let horaExtra100:number;

      // verifica se há valores em ambos os campos de horário trabalhado
      if (contHour[i].Horario_Trabalhado.Inicio&&contHour[i].Horario_Trabalhado.Fim) {

        // transforma o valor em string para número
        let horaIni = this.hourToNumber(contHour[i].Horario_Trabalhado.Inicio);
        let horaFim = this.hourToNumber(contHour[i].Horario_Trabalhado.Fim);

        // se a hora final for 0, passa o valor para 24
        if (horaFim == 0) horaFim = 24;
        let intAlmoco:number;
        let diaDaSemana = contHour[i].Dia_da_Semana;

        // se houver valor em intervalo de almoço, passa-o para número
        // caso contrário atribui 0
        if (contHour[i].Intervalo_de_Almoco) intAlmoco = this.hourToNumber(contHour[i].Intervalo_de_Almoco);
        else intAlmoco = 0;
        
        // verifica o tipo de dia: útil, sábado ou domingo/feriado
        if (diaDaSemana == "Dia Útil") {
      
          // realiza o cálcula de hora normal e hora extra 50% para dia útil
          [horaNormal, horaExtra50] = this.calcHora(horaIni, horaFim);

          // desconta o intervalo de almoço
          horaNormal = horaNormal - intAlmoco;

          // atribui 0 a hora extra 100%
          horaExtra100 = 0
      
        } else if (diaDaSemana == "Sábado") {
      
          // atribui 0 para hora normal e hora extra 100% e cálcula valor da hora extra 50% 
          horaNormal = 0;
          horaExtra50 = horaFim - horaIni - intAlmoco;
          horaExtra100 = 0
      
        } else {
      
          // atribui 0 para hora normal e hora extra 50% e cálcula valor da hora extra 100% 
          horaNormal = 0;
          horaExtra50 = 0;
          horaExtra100 = horaFim - horaIni - intAlmoco;
      
        }

      } else {
        // caso não haja valor nos campos de horário trabalhado, atribui 0 aos tipos de hora
        horaNormal = 0;
        horaExtra50 = 0;
        horaExtra100 = 0;
      
      }

      // arredonda valores e os coloca em horas 
      // horas contém os valores calculados para cada dia
      this.horas.horaNormal[i] = (Math.round(horaNormal*100)/100);
      this.horas.horaExtra50[i] = (Math.round(horaExtra50*100)/100);
      this.horas.horaExtra100[i] = (Math.round(horaExtra100*100)/100);

      // soma todos os valores de hora normal, hora extra 50% e hora extra 100%, 
      // e os coloca em soma1, soma2 e soma3 respectivamente
      soma1 += horaNormal;
      soma2 += horaExtra50;
      soma3 += horaExtra100;
    }
    return [(Math.round(soma1*100)/100), (Math.round(soma2*100)/100), (Math.round(soma3*100)/100)];
  }

  // realiza o cálculo de deslocamento normal e deslocamentos extras do relatório
  private calcHorasDeslocamento(contHour:ControleDeHoras[], length:number):number[] {
   
    let soma1 = 0;
    let soma2 = 0;
    let soma3 = 0;
    
    for (let i = 0; i < length; i++) {
      
      let deslNormal:number;
      let deslExtra50:number;
      let deslExtra100:number;
      let horaIni1:number;
      let horaFim1:number;
      let horaIni2:number;
      let horaFim2:number;
      let diaDaSemana = contHour[i].Dia_da_Semana;

      // verifica se há valores em ambos os campos de deslocamento ida
      if (contHour[i].Deslocamento_Ida.Inicio&&contHour[i].Deslocamento_Ida.Fim) {
        // transforma o valor de string para número
        horaIni1 = this.hourToNumber(contHour[i].Deslocamento_Ida.Inicio);
        horaFim1 = this.hourToNumber(contHour[i].Deslocamento_Ida.Fim);

        // se a hora final for 0, passa o valor para 24
        if (horaFim1 == 0) horaFim1 = 24;
      } else {
        // caso não haja valores nos campos, atribui undefined 
        horaIni1 = undefined;
        horaFim1 = undefined;
      }

      // verifica se há valores em ambos os campos de deslocamento volta
      if (contHour[i].Deslocamento_Volta.Inicio&&contHour[i].Deslocamento_Volta.Fim) {
        // transforma o valor de string para número
        horaIni2 = this.hourToNumber(contHour[i].Deslocamento_Volta.Inicio);
        horaFim2 = this.hourToNumber(contHour[i].Deslocamento_Volta.Fim);

        // se a hora final for 0, passa o valor para 24
        if (horaFim2 == 0) horaFim2 = 24;
      } else {
        // caso não haja valores nos campos, atribui undefined
        horaIni2 = undefined;
        horaFim2 = undefined;
      }
        
      // verifica o tipo de dia: útil, sábado ou domingo/feriado
      if (diaDaSemana == "Dia Útil") {

        let deslnorm1 = 0;
        let deslnorm2 = 0;
        let deslextra1 = 0;
        let deslextra2 = 0;

        // se os valores são diferentes de undefined, calcula os deslocamentos normal e extra 50
        if ((horaIni1!==undefined)&&(horaFim1!==undefined)) {
          [deslnorm1, deslextra1] = this.calcHora(horaIni1, horaFim1);
        }

        if ((horaIni2!==undefined)&&(horaFim2!==undefined)) {
          [deslnorm2, deslextra2] = this.calcHora(horaIni2, horaFim2);
        }

        // soma resultados dos deslocamentos 1 e 2 para deslocamento 50% e normal
        deslNormal = deslnorm1 + deslnorm2;
        deslExtra50 = deslextra1 + deslextra2;

        // atribui 0 ao deslocamento 100%
        deslExtra100 = 0

      } else if(diaDaSemana == "Sábado") {

        let deslextra1 = 0;
        let deslextra2 = 0;
        
        // se os valores são diferentes de undefined, calcula os deslocamentos extra 50%
        if ((horaIni1!==undefined)&&(horaFim1!==undefined))  {
          deslextra1 = horaFim1 - horaIni1;
        } 
        if ((horaIni2!==undefined)&&(horaFim2!==undefined)) {
          deslextra2 = horaFim2 - horaIni2;
        }

        // soma resultados dos deslocamentos 1 e 2 para deslocamento 50% e normal
        deslExtra50 = deslextra1 + deslextra2;

        // atribui 0 para deslocamento normal e extra 100%
        deslNormal = 0;
        deslExtra100 = 0

      } else {
        let deslextra1 = 0;
        let deslextra2 = 0;
        
        // se os valores são diferentes de undefined, calcula os deslocamentos extra 100%
        if ((horaIni1!==undefined)&&(horaFim1!==undefined))  {
          deslextra1 = horaFim1 - horaIni1;
        } 
        if ((horaIni2!==undefined)&&(horaFim2!==undefined)) {
          deslextra2 = horaFim2 - horaIni2;
        }

        // atribui 0 para deslocamento normal e extra 50%
        deslNormal = 0;
        deslExtra50 = 0;

        // soma resultados dos deslocamentos 1 e 2 para deslocamento 100% e normal
        deslExtra100 = deslextra1 + deslextra2;
      }

      // arredonda valores e os coloca em horas 
      // horas contém os valores calculados para cada dia
      this.horas.deslNormal[i] = (Math.round(deslNormal*100)/100);
      this.horas.deslExtra50[i] = (Math.round(deslExtra50*100)/100);
      this.horas.deslExtra100[i] = (Math.round(deslExtra100*100)/100);

      // soma todos os valores de deslocamento normal, deslocamento extra 50% 
      // e deslocamento extra 100%, e os coloca em soma1, soma2 e soma3, respectivamente
      soma1 += deslNormal;
      soma2 += deslExtra50;
      soma3 += deslExtra100;
    }
    return [(Math.round(soma1*100)/100), (Math.round(soma2*100)/100), (Math.round(soma3*100)/100)];
  }

  // transforma a hora de string para um valor numérico em horas
  public hourToNumber(value:string):number {
    return Math.round((parseFloat(value.substring(0,2)) + parseFloat(value.substring(3))/60)*100)/100;
  }

  // calcula os valores de hora/desl normal e hora/desl extra 50% p/ dias uteis
  private calcHora(horaInicial:number, horaFinal:number):number[] {
    // horaTrabIni e horaTrabFim recebem horário normal de trabalho
    let horaTrabIni = this.horaNormalIni; 
    let horaTrabFim = this.horaNormalFim;
    let horaExtraIni:number;
    let horaExtraFim:number;
    let horaNormIni:number;
    let horaNormFinal:number;
    let horaExtra:number;
 
    if ((horaInicial < horaTrabFim) && (horaFinal > horaTrabIni)) {
      if (horaInicial < horaTrabIni){
        horaNormIni = horaTrabIni;
        horaExtraIni = horaInicial;
      } else {
        horaNormIni = horaInicial;
        horaExtraIni = horaTrabFim;
      }
      if (horaTrabFim < horaFinal){
        horaNormFinal = horaTrabFim;
        horaExtraFim = horaFinal;
      } else {
        horaNormFinal = horaFinal;
        horaExtraFim = horaTrabIni;
      }
    
      if ((horaExtraFim == horaTrabIni)&&(horaExtraIni == horaTrabFim)){
        horaExtra = 0;
      } 
      else if ((horaExtraIni < horaTrabIni)&&(horaExtraFim > horaTrabFim)){
        horaExtra = horaExtraFim - horaTrabFim + horaTrabIni - horaExtraIni;
      }
      else {
        horaExtra = horaExtraFim - horaExtraIni;
      }
      return  [(horaNormFinal - horaNormIni), horaExtra];
    }
    else {
      return [0, (horaFinal - horaInicial)];
    }
}

  // passa modo de edição p/ criação ou o inverso
  public setEditMode(editMode:boolean):void {
      this.editMode = editMode;
  }

  // salva refêrencias presentes em pesquisa-relatorio, que serão usadas por outros componentes
  public setRef(ref:any, autor?:string):void {
    if (typeof(ref) == 'string') {
      this.ref = [];
      this.ref[0] = ref; 
      this.autorRelAtual = autor;
      this.relatorioAtual = ref.substring(45);
    } else {
      this.ref = ref;
    }
  }

}
