import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  public isPersistent = false;

  constructor(public af: AngularFirestore) {

  }

}
