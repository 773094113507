<div class="container" *ngIf='emailSent'>
  <h3>Um email foi enviado para {{Email}}</h3>
  <a routerLink="/login">Voltar para a página inicial</a>
</div>
<div class="container" *ngIf='!emailSent'>
  <div id="box">
    <br>
    <h4>Por favor, insira seu endereço de e-mail</h4>
    <mat-form-field id="email">
      <input matInput placeholder="e-mail" #email>
    </mat-form-field><br>
    <button mat-button (click)="enviarEmail()">Enviar e-mail de redefinição de senha</button>
  </div>
  <h5 *ngIf='error'>{{error}}</h5>
</div>
