import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AppComponent } from './app.component';
import { RedefinirSenhaComponent } from './components/redefinir-senha/redefinir-senha.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const appRoutes: Routes = [
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  { path: 'redefinir-senha', component:RedefinirSenhaComponent },
  { path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule), 
  canActivate: [AuthGuard] },
  { path: '', component: AppComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
